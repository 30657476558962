import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {List, ListItem, ListItemText,ListItemIcon } from '@material-ui/core';
import { hymns } from './hymns.json';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
});

const listItems = hymns.map(hymn => {
  let icon = hymn.version
    ? `${hymn.number} (${hymn.version})`
    : `${hymn.number}`;
  return (
    <ListItem button component="a" href={`/hymn/${hymn.url}`} key={hymn.url}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={hymn.verses[0][0]} />
    </ListItem>
  );
});

function Hymnlist(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <List component="nav">{listItems}</List>
    </div>
  );
}

Hymnlist.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Hymnlist);
