import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { MenuItem,Paper,TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { hymns } from './hymns.json';

const suggestions = hymns;

function renderInput(inputProps) {
  const { classes, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      inputRef={ref}
      InputProps={{
        classes: {
          input: classes.input
        },
        ...other
      }}
    />
  );
}

function makeSearchTerm(hymn) {
  let icon = hymn.version
    ? `${hymn.number} (${hymn.version})`
    : `${hymn.number}`;
  return icon + ' ' + hymn.verses[0][0];
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(makeSearchTerm(suggestion), query);
  const parts = parse(makeSearchTerm(suggestion), matches);
  return (
    <MenuItem
      selected={isHighlighted}
      component="a"
      href={`/hymn/${suggestion.url}`}
      key={suggestion.url}
    >
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return makeSearchTerm(suggestion);
}

function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
        let keep = false;
        if (count < 5) {
          let searchTerm = makeSearchTerm(suggestion).toLowerCase();
          if (searchTerm.indexOf(' ' + inputValue) > 0) {
            //for finding words which have a space before them, dont want mid word
            keep = searchTerm.indexOf(' ' + inputValue) > 0;
          } else {
            //for start of line
            keep = searchTerm.indexOf(inputValue) === 0;
          }
        }
        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    marginBottom: 20
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  }
});

class Search extends React.Component {
  state = {
    value: '',
    suggestions: []
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <label htmlFor="hymnSearch">SEARCH HYMNS</label>
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderInputComponent={renderInput}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          renderSuggestionsContainer={renderSuggestionsContainer}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            classes,
            placeholder: 'Input number or first line',
            value: this.state.value,
            onChange: this.handleChange
          }}
          name="hymnSearch"
        />
      </div>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Search);
