import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './Header';
import Hymnlist from './Hymnlist';
import Hymn from './Hymn';
import Home from './Home';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import './App.css';

const styles = theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3
  },
  root: {
    flexGrow: 1,
    //height: 430,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  toolbar: theme.mixins.toolbar
});

class App extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <CssBaseline />
        <div className={classes.root}>
          <Header />
          <Router>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <Route exact path="/" component={Home} />
              <Route path="/hymn/:url" component={Hymn} />
              <Route path="/list/" component={Hymnlist} />
            </main>
          </Router>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);
