import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {Card,CardContent,Typography} from '@material-ui/core';
import Search from './Search';

import { hymns } from './hymns.json';

const styles = theme => ({
  card: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.palette.text.secondary
  },
  pos: {
    marginBottom: 12,
    color: theme.palette.text.secondary
  }
});

function Hymn(props) {
  const { classes } = props;

  let currentHymn = hymns.find(function(hymn) {
    return hymn.url === props.match.params.url;
  });

  let verses = currentHymn.verses.map((verse, i) => {
    let chorus = '';
    if (i === 0 && currentHymn.chorus.length > 0) {
      chorus = (
        <p>
          <br />
          {currentHymn.chorus.map((line, i) => {
            return (
              <em key={i}>
                {line}
                <br />
              </em>
            );
          })}
          <br />
        </p>
      );
    }
    return (
      <div key={i}>
        <p>
          <strong>{i + 1} </strong>
          {verse.map((line, i) => {
            return (
              <span key={i}>
                {line}
                <br />
              </span>
            );
          })}
        </p>
        {chorus}
      </div>
    );
  });

  let icon = currentHymn.version
    ? `${currentHymn.number} (${currentHymn.version})`
    : `${currentHymn.number}`;

  return (
    <div className={classes.root}>
      <div>
        <Card className={classes.card}>
          <CardContent>
            <Search />
            <Typography variant="headline" component="h2">
              {icon}
            </Typography>
            <Typography className={classes.title}>
              {currentHymn.title}
            </Typography>
            <Typography className={classes.pos}>{currentHymn.metre}</Typography>
            <Typography component="div">{verses}</Typography>
            <Typography className={classes.title}>
              {currentHymn.author}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

Hymn.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Hymn);
