import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardContent  from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/styles';
import Search from './Search';

const styles = theme => ({
  card: {
    minWidth: 275,
    minHeight: 600
  }
});

function Home(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div>
        <Card className={classes.card}>
          <CardContent>
            <Search />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);
